<!--
 * @Author: 候怀烨
 * @Date: 2020-11-16 10:43:03
 * @LastEditTime: 2020-12-14 14:12:43
 * @LastEditors: Please set LastEditors
 * @Description: 编辑部门
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\departmentDetail\components\editDetail.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="编辑"
      :visible.sync="dialogVisible"
      width="40%"
      center
    >
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="部门名称" prop="depart_name">
            <el-input
              v-model="form.depart_name"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item label="上级部门" prop="pid">
            <el-select
              v-model="form.pid"
              placeholder="请选择上级部门"
              style="width: 300px"
            >
              <el-option
                v-for="(item, index) in department"
                :key="index"
                :label="item.text"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="部门主管" prop="manage_id">
            <el-select
              v-model="form.manage_id"
              placeholder="请选择部门主管"
              style="width: 300px"
            >
              <el-option
                v-for="(lists, index) in employee"
                :key="index"
                :label="lists.user_name"
                :value="lists.user_id"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item label="状态" prop="is_close">
            <el-radio-group v-model="form.is_close">
              <el-radio label="0">正常</el-radio>
              <el-radio label="1">停用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clear">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    props: {
      list: {
        type: Array,
        default() {
          return []
        },
      },
      employee: {
        type: Array,
        default() {
          return []
        },
      },
    },
    data() {
      return {
        dialogVisible: false,
        form: {
          depart_name: '',
          pid: '',
          // manage_id: '',
          is_close: '',
        },
        rules: {
          depart_name: [
            { required: true, message: '请输入部门名称', trigger: 'blur' },
          ],
        },
        url: {
          editbm: '/baseAdmin/depart/update',
          getbm: '/baseAdmin/depart/detail',
        },
      }
    },
    computed: {
      department() {
        return this.list
      },
    },
    mounted() {},
    methods: {
      handlerData(row) {
        postAction(this.url.getbm, { id: row.id })
          .then((res) => {
            console.log(res, '获取成功')
            this.form = res.data
            delete this.form.area_id
            delete this.form.area_name
            delete this.form.depot_id
            delete this.form.depot_name
            delete this.form.brand_id
            delete this.form.brand_name
          })
          .catch((err) => {
            console.log(err, '获取失败')
          })
      },
      submitForm() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            postAction(this.url.editbm, this.form)
              .then((res) => {
                console.log(res, '提交成功')
                if (res.code == 200) {
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  })
                  this.$emit('shuxian')
                  this.$refs['form'].resetFields()
                  this.dialogVisible = false
                }
              })
              .catch((err) => {
                console.log(err, '提交失败')
              })
          } else {
            this.$message({
              message: '请填写完必填项后再提交',
              type: 'warning',
            })
            return false
          }
        })
      },
      clear() {
        this.$refs['form'].resetFields()
        this.dialogVisible = false
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
    },
  }
</script>

<style></style>
