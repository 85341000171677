<template>
  <div>
    <!-- <span v-for="item in list" :key="item">
      {{ item.depot_name }}
      <br />
    </span> -->
    <el-alert
      title="请为员工分配仓库权限，内勤/库管建议勾选全部权限，业务/司机建议勾选调拨单、查询权限"
      type="success"
      effect="dark"
    ></el-alert>
    <el-table stripe :data="data" height="500px">
      <!-- 选择框 -->
      <el-table-column align="center" width="50">
        <template slot="header">默认</template>
        <template #default="{ row }">
          <el-checkbox
            v-if="row.depot_type == 1"
            v-model="row.is_default"
            :true-label="1"
            :false-label="0"
            @change="selectChangeA($event, row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '仓库'">
            <span>{{ row[item.prop] }}</span>
          </div>
          <div v-else-if="item.label == '全部权限'">
            <el-checkbox
              v-model="row.auth_all"
              :true-label="1"
              :false-label="0"
              @change="allPermissionsSelect($event, row)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '入库单'">
            <el-checkbox
              v-model="row.auth_1"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 1)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '出库单'">
            <el-checkbox
              v-model="row.auth_2"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 2)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '调拨'">
            <el-checkbox
              v-model="row.auth_3"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 3)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '盘点'">
            <el-checkbox
              v-model="row.auth_4"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 4)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '查询'">
            <el-checkbox
              v-model="row.auth_5"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 5)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '审核'">
            <el-checkbox
              v-model="row.auth_6"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 6)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '作废'">
            <el-checkbox
              v-model="row.auth_7"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 7)"
            ></el-checkbox>
          </div>
          <div v-else-if="item.label == '冲改'">
            <el-checkbox
              v-model="row.auth_8"
              :true-label="1"
              :false-label="0"
              @change="selectRow($event, row, 8)"
            ></el-checkbox>
          </div>
          <div v-else></div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      选中的仓库，将作为下单、调拨、退库等操作的默认仓库
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { depotSubmit } from '@/api/staffPermissions'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Depot',
    components: {},
    // eslint-disable-next-line vue/require-prop-types
    props: ['data', 'id'],
    data() {
      return {
        list: [],
        checkList: [
          '仓库',
          '全部权限',
          // '入库单',
          // '出库单',
          '调拨',
          '盘点',
          '查询',
          '审核',
          '作废',
          // '冲改',
        ],
        columns: [
          {
            order: 1,
            label: '仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 2,
            label: '全部权限',
            prop: '',
            width: '',
          },
          // {
          //   order: 3,
          //   label: '入库单',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 4,
          //   label: '出库单',
          //   prop: '',
          //   width: '',
          // },
          {
            order: 5,
            label: '调拨',
            prop: '',
            width: '',
          },
          {
            order: 6,
            label: '盘点',
            prop: '',
            width: '',
          },
          {
            order: 7,
            label: '查询',
            prop: '',
            width: '',
          },
          {
            order: 8,
            label: '审核',
            prop: '',
            width: '',
          },
          {
            order: 9,
            label: '作废',
            prop: '',
            width: '',
          },
          // {
          //   order: 10,
          //   label: '冲改',
          //   prop: '',
          //   width: '',
          // },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      data(val) {
        console.log('datachange', val)
        this.list = val
      },
    },
    created() {},
    mounted() {},
    methods: {
      selectChangeA(val, row) {
        this.data.forEach((item) => {
          item.is_default = 0
        })
        console.log(val, row)
        row.is_default = val
      },
      // 行内全部选中按钮取消
      cancelRowAllSelect(row) {
        row.auth_all = 0
      },
      selectRow(val, row, auth = 1) {
        // console.log(eval('row.auth_' + auth))
        // eval('row.auth_' + auth)
        if (!val) {
          this.cancelRowAllSelect(row)
        } else {
          let i = 0
          Object.keys(row).forEach((key) => {
            if (key.indexOf('auth') >= 0 && key.indexOf('auth_a') < 0) {
              if (row[key] == 1) {
                i += 1
              }
            }
          })
          console.log(i)
          if (i == 8) {
            row.auth_all = 1
          } else {
            this.cancelRowAllSelect(row)
          }
        }
      },
      // 全选一行
      allRowSelect(row, select = 1) {
        Object.keys(row).forEach((key) => {
          if (key.indexOf('auth') >= 0 && key.indexOf('auth_a') < 0) {
            row[key] = select
          }
        })
      },
      allPermissionsSelect(val, row) {
        console.log(val, row)
        if (val) {
          // 全选
          this.allRowSelect(row)
        } else {
          // 取消全选
          this.allRowSelect(row, 0)
        }
      },
      close() {
        this.$emit('close')
      },
      handleSave() {
        const depotData = {
          depart_id: this.id,
          depot_data: JSON.stringify(this.data),
        }
        console.log(depotData, '仓库保存')
        postAction('/baseAdmin/depart/depot-rel', depotData).then((res) => {
          this.$emit('refresh')
          this.$message.success(res.msg)
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
