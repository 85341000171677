<!--
 * @Author: 候怀烨
 * @Date: 2020-11-11 14:09:29
 * @LastEditTime: 2020-11-23 13:27:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\departmentDetail\index.vue
-->
<!-- NAME[epic=基础] 部门资料 -->
<template>
  <div class="wrapper">
    <div class="padding">
      <el-row type="flex" class="row-bg" justify="end">
        <add-detail
          :list="list"
          :employee="employee"
          @shuxian="shuxian"
        ></add-detail>
      </el-row>
      <el-row class="tables">
        <el-table
          stripe
          :data="treelist"
          style="width: 100%; margin-bottom: 20px"
          row-key="id"
          border
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            v-for="(item, index) in satfflist"
            :key="index"
            :prop="item.prop"
            :align="item.align"
            :label="item.label"
            width=""
          ></el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="300"
          >
            <template #default="{ row }">
              <div v-if="row.is_close == 0">
                <div v-show="row.pid != 0">
                  <el-button type="text" size="small" @click="edit(row)">
                    编辑
                  </el-button>
                  <el-button type="text" size="small" @click="quanxian(row)">
                    数据权限
                  </el-button>
                  <!-- <el-popconfirm title="这是一段内容确定删除吗？">
                    <el-button type="text" size="small">删除</el-button>
                  </el-popconfirm> -->
                </div>
              </div>
              <div v-else>
                <el-button type="text" @click="handleStart(row)">
                  启用
                </el-button>
                <el-button type="text" @click="handleDelete(row)">
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
    <edit-detail
      ref="editdet"
      :list="list"
      :employee="employee"
      @shuxian="shuxian"
    ></edit-detail>
    <permissions ref="permissions" @refresh="handlerData"></permissions>
  </div>
</template>

<script>
  import Permissions from './components/permissions'

  import AddDetail from './components/addDetail'
  import { postAction } from '@/api/Employee'
  import EditDetail from './components/editDetail.vue'
  export default {
    name: 'DepartmentDetail',
    components: {
      AddDetail,
      EditDetail,
      Permissions,
    },
    data() {
      return {
        listing: {},
        employee: [], //员工
        list: [],
        treelist: [],
        satfflist: [
          {
            order: 1,
            label: '部门',
            align: 'left',
            width: '300px',
            prop: 'text',
            sortable: false,
          },
          {
            order: 2,
            prop: 'area_name',
            align: 'center',
            label: '销售区域',
            width: '400px',
          },
          {
            order: 3,
            prop: 'is_close_text',
            align: 'center',
            label: '状态',
            width: '200px',
          },
        ],
        url: {
          datalist: '/baseAdmin/depart/index',
          Employee: '/baseAdmin/user/index',
        },
      }
    },

    mounted() {
      this.handlerData()
      this.handlerEmployee()
    },
    methods: {
      //数据权限
      quanxian(row) {
        console.log('row', row)
        console.log(row)
        this.$refs.permissions.id = row.id
        this.$refs.permissions.showDialog = true
        // this.listing = row
        // this.$refs.shuj.handlerData(row)
        // this.$refs.shuj.dialogVisible = true
      },
      //编辑
      edit(row) {
        console.log(row)
        this.$refs.editdet.handlerData(row)
        this.$refs.editdet.dialogVisible = true
      },
      shuxian() {
        this.handlerData()
      },
      handlerData() {
        postAction(this.url.datalist, {}).then((res) => {
          console.log(res, '成功')
          this.treelist = res.data.tree
          this.list = res.data.list
        })
      },
      handlerEmployee() {
        const pages = {
          pageNo: 1,
          pagesize: 1000,
        }
        postAction(this.url.Employee, pages)
          .then((res) => {
            console.log(res, '人员成功')
            this.employee = res.data
          })
          .catch((err) => {
            console.log(err, '人员失败')
          })
      },
      handleStart(row) {
        postAction('/baseAdmin/depart/status', {
          depart_id: row.id,
          status: 0,
        }).then((r) => {
          this.$message.success('启用成功')
          this.handlerData()
        })
      },
      handleDelete(row) {
        this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction('/baseAdmin/depart/del', { depart_id: row.id }).then(
              (res) => {
                this.handlerData()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            )
          })
          .catch(() => {})
      },
    },
  }
</script>
<style scoped>
  .padding {
    padding: 20px;
  }
  .tables {
    margin-top: 20px;
  }
</style>
