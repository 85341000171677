<template>
  <div class="orderTest-container">
    <el-alert
      title="默认为全部商品，勾选后，业务只可操作有权限的商品下单"
      type="success"
      effect="dark"
    ></el-alert>
    <div
      v-loading="allLoading"
      style="display: flex; justify-content: space-around"
    >
      <div style="width: 162px">
        <el-table stripe :data="classList" border height="500px">
          <!-- 选择框 -->
          <el-table-column align="center" width="50">
            <template slot="header">
              <el-checkbox @change="handleChangeClassAll"></el-checkbox>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-model="row.is_rel"
                :true-label="1"
                :false-label="0"
                @change="handleClassSelect($event, row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="class_name"
            label="品类"
            align="center"
          ></el-table-column>
        </el-table>
      </div>

      <!-- 品牌表格 -->
      <div style="width: 280px">
        <el-table
          stripe
          :data="brandList"
          border
          height="500px"
          row-key="id"
          default-expand-all
          :indent="35"
          :tree-props="{
            children: 'children',
            hasChildren: 'hasChildren',
          }"
          highlight-current-row
          @current-change="handleCurrentChange"
        >
          >
          <!-- 选择框 -->
          <el-table-column type="" align="center" width="50">
            <template slot="header">
              <el-checkbox @change="handleChangeBrandAll"></el-checkbox>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-model="row.is_rel"
                :true-label="1"
                :false-label="0"
                @change="handleBrandSelect($event, row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="text" label="品牌" align="center">
            <template slot="header">
              <span>品牌</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 商品表格 -->
      <div style="width: 390px">
        <el-table
          ref="goodsTable"
          v-loading="rTableLoding"
          stripe
          :data="filterGoodsData"
          border
          height="500px"
          row-key="goods_id"
        >
          <!-- @selection-change="asd" -->
          <!-- 选择框 -->
          <!-- <el-table-column width="50" align="center">
            <template #default="{ row }">
              <el-checkbox
                v-model="row.is_rel"
                :true-label="1"
                :false-label="0"
                @change="handleGoodsSelect($event, row)"
              ></el-checkbox>
            </template>
          </el-table-column> -->
          <!-- 商品表格 选择框 -->
          <el-table-column align="center" width="80">
            <template slot="header" slot-scope="scope">
              <!-- {{ scope.column }} -->
              <!-- <input
                type="checkbox"
                v-model="goodsAllS"
                @change="handleGoodsAllSelect"
              /> -->

              <label style="color: red">
                <el-checkbox
                  v-model="goodsAllS"
                  @change="handleGoodsAllSelect($event, scope)"
                ></el-checkbox>
                全选
              </label>
            </template>
            <template #default="{ row }">
              <el-checkbox
                v-model="row.is_rel"
                :true-label="1"
                :false-label="0"
                @change="handleGoodsSelect($event, row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="goods_name"
            label="商品名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="specs"
            label="规格"
            align="center"
            width="110"
          ></el-table-column>
        </el-table>
      </div>
    </div>

    <div style="margin: 5px 18px">
      <el-checkbox
        v-model="true1"
        label="给予全部商品权限"
        @change="hanldeCheckAll($event, 1)"
      ></el-checkbox>
      <el-checkbox
        v-model="true2"
        label="无商品权限"
        @change="hanldeCheckAll($event, 0)"
      ></el-checkbox>
      <!-- <el-button @click="hanldeCheckAll(1)">勾选全部权限</el-button>
      <el-button @click="hanldeCheckAll(0)">清空全部权限</el-button> -->
    </div>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button type="primary" @click="handleSave">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </div>
</template>
<script>
  import { goodsListBMQX, goodsSubmit } from '@/api/staffPermissions'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'GoodsPermissions',
    components: {},
    props: {
      id: {
        type: [String, Number],
        default: () => null,
      },
    },
    data() {
      return {
        true1: false,
        true2: false,
        allLoading: false,
        classAllCheckBox: false,
        brandAllCheckBox: false,
        isAllBrand: [],
        goodsSelectList: [],
        goodsAllS: false,
        classList: [],
        brandList: [],
        goodsList: [],
        brandSelectList: [],
        classSelectList: [],
        // 选中的品牌id
        brandId: [],
        rTableLoding: false,
        // 点选的当前品牌
        currentBrandRow: {},
        allGoodsData: [],
        // filterGoodsData: [],
      }
    },
    computed: {
      filterGoodsData() {
        let brandId = this.currentBrandRow.id
        return this.goodsList.filter((i) => i.brand_id == brandId)
        // if (!this.currentBrandRow.id) {
        //   return this.goodsList
        // } else {
        //   let brandId = this.currentBrandRow.id

        // }
      },
    },
    watch: {},
    created() {
      console.log('goods created')
      // this.getBrandData()
      // this.getClassData()
      // this.getGoodsData()
    },
    mounted() {},
    methods: {
      async getBrandData() {
        let { data, msg, code } = await postAction(
          '/baseAdmin/depart/brand-rel-list',
          { depart_id: this.id }
        )
        if (code == 200) {
          this.brandList = data.tree
        } else {
          this.$message.error(msg)
        }
      },
      async getClassData() {
        let { data, msg, code } = await postAction(
          '/baseAdmin/depart/goods-class-rel-list',
          { depart_id: this.id }
        )
        if (code == 200) {
          console.log(data)
          this.classList = data
        } else {
          this.$message.error(msg)
        }
      },
      // 选中全部按钮
      selectAll() {
        setTimeout(() => {
          this.hanldeCheckAll(true, 1)
        }, 800)
      },
      async getGoodsData() {
        this.allLoading = true
        postAction('/baseAdmin/depart/goods-list', {
          depart_id: this.id,
        }).then((res) => {
          console.log('new   asd', res)
          if (res.data.all_clear == 1) {
            this.true1 = true
            this.selectAll()
          } else if (res.data.all_clear == 2) {
            this.true2 = true
          }
        })
        let { data, msg, code } = await postAction(
          '/baseAdmin/depart/goods-rel-list',
          { depart_id: this.id }
        )
        if (code == 200) {
          console.log(data)
          this.goodsList = data
          this.allLoading = false
        } else {
          this.allLoading = false
          this.$message.error(msg)
        }
      },
      // 递归处理空children数组
      // getTreeData(arr) {
      //   arr.forEach((item) => {
      //     if (item.children.length == 0) {
      //       item.children = undefined
      //     } else {
      //       this.getTreeData(item.children)
      //     }
      //   })
      // },
      // checkbox
      handleClassSelect(val, row) {
        console.log(row)
        this.true1 = false
        this.true2 = false
      },
      // 递归处理空children数组
      // getTreeData2(arr) {
      //   arr.forEach((item) => {
      //     if (!item.children) {
      //       if (item.is_rel == 1) {
      //         this.brandId.push(item.id)
      //       }
      //     } else {
      //       this.getTreeData(item.children)
      //     }
      //   })
      // },
      handleBrandSelect(val, row) {
        // this.rTableLoding = true
        this.brandId = []
        console.log('选中了')
        console.log(val, row)
        this.true1 = false
        this.true2 = false
        this.filterGoodsData.forEach((i) => {
          i.is_rel = val == 1 ? 1 : 0
        })
        if (val && row.children) {
          row.children.forEach((q) => {
            this.goodsList
              .filter((i) => i.brand_id == q.id)
              .forEach((cc) => {
                console.log('ccc', cc)
                cc.is_rel = val == 1 ? 1 : 0
              })
            q.is_rel = val
            if (q.children) {
              q.children.forEach((z) => {
                this.goodsList
                  .filter((i) => i.brand_id == z.id)
                  .forEach((cc) => {
                    console.log('ccc', cc)
                    cc.is_rel = val == 1 ? 1 : 0
                  })
                z.is_rel = val
                if (z.children) {
                  z.children.forEach((a) => {
                    this.goodsList
                      .filter((i) => i.brand_id == a.id)
                      .forEach((cc) => {
                        console.log('ccc', cc)
                        cc.is_rel = val == 1 ? 1 : 0
                      })
                    a.is_rel = val
                  })
                }
              })
            }
          })
        }
        let l = 0
        this.filterGoodsData.forEach((i) => {
          if (i.is_rel == 1) {
            l++
          }
        })
        console.log('lll', l, this.filterGoodsData.length)
        if (l == this.filterGoodsData.length && l != 0) {
          this.goodsAllS = true
          this.isAllBrand.push(this.currentBrandRow.id)
        } else {
          this.goodsAllS = false
          this.isAllBrand.forEach((i, idx) => {
            if (i == this.currentBrandRow.id) {
              this.isAllBrand.splice(idx, 1)
            }
          })
        }
        // this.getTreeData2(this.brandList)
        // this.brandList.forEach((item) => {
        //   if (item.is_rel == 1) {
        //     this.brandId.push(item.id)
        //   }
        // })

        // goodsList({
        //   depart_id: this.id,
        //   brand_id: this.currentBrandRow.id,
        // }).then((res) => {
        //   this.goodsList = res.data
        //   if (val == 1) {
        //     row.is_rel = 1
        //     this.goodsList.forEach((item) => {
        //       this.$refs.goodsTable.toggleRowSelection(item, true)
        //     })
        //   } else {
        //     console.log('asd')
        //     let this_ = this
        //     setTimeout(() => {
        //       this_.goodsList.forEach((item) => {
        //         console.log('itemasd', item)
        //         this_.$refs.goodsTable.toggleRowSelection(item, false)
        //       })
        //       row.is_rel = 0
        //     }, 100)
        //   }
        //   this.rTableLoding = false
        // })
      },
      handleGoodsSelect(val, row) {
        if (val) {
          this.currentBrandRow.is_rel = 1
        }
        let l = 0
        this.filterGoodsData.forEach((i) => {
          if (i.is_rel == 1) {
            l++
          }
        })
        console.log('lll', l, this.filterGoodsData.length)
        this.true1 = false
        this.true2 = false
        if (l == this.filterGoodsData.length && l != 0) {
          this.goodsAllS = true
          this.isAllBrand.push(this.currentBrandRow.id)
        } else {
          this.goodsAllS = false
          this.isAllBrand.forEach((i, idx) => {
            if (i == this.currentBrandRow.id) {
              this.isAllBrand.splice(idx, 1)
            }
          })
        }
      },

      close() {
        this.$emit('close')
      },
      handleSave() {
        let classId = [],
          brandId = [],
          goodsId = []
        this.classList.forEach((item) => {
          if (item.is_rel == 1) {
            classId.push(item.class_id)
          }
        })
        this.brandList.forEach((item) => {
          if (item.is_rel == 1) {
            brandId.push(item.id)
          }
          if (item.children && item.children.length != 0) {
            item.children.forEach((item2) => {
              if (item2.is_rel == 1) {
                brandId.push(item2.id)
              }
            })
          }
        })
        this.goodsList.forEach((item) => {
          if (item.is_rel == 1) {
            goodsId.push(item.goods_id)
          }
        })
        classId = JSON.stringify(classId) == '[]' ? '' : JSON.stringify(classId)
        brandId = JSON.stringify(brandId) == '[]' ? '' : JSON.stringify(brandId)
        goodsId = JSON.stringify(goodsId) == '[]' ? '' : JSON.stringify(goodsId)
        let is_all = this.isAllBrand
        const endData = {
          depart_id: this.id,
          goods_class_data: classId,
          brand_data: brandId,
          goods_data: goodsId,
          is_all,
          all_clear: this.true1 ? 1 : this.true2 ? 2 : null,
        }
        postAction('/baseAdmin/depart/goods-rel', endData).then((res) => {
          this.$message.success(res.msg)
        })
        // console.log(endData)
      },
      handleCurrentChange(row) {
        this.currentBrandRow = row
        // this.rTableLoding = true
        console.log('current', row)
        if (this.currentBrandRow.length == 0) {
          this.goodsAllS = false
          this.isAllBrand.forEach((i, idx) => {
            if (i == this.currentBrandRow.id) {
              this.isAllBrand.splice(idx, 1)
            }
          })
        }
        let l = 0
        this.filterGoodsData.forEach((i) => {
          if (i.is_rel == 1) {
            l++
          }
        })
        console.log('lll', l, this.filterGoodsData.length)
        if (l == this.filterGoodsData.length && l != 0) {
          this.goodsAllS = true
          this.isAllBrand.push(this.currentBrandRow.id)
        } else {
          this.goodsAllS = false
          this.isAllBrand.forEach((i, idx) => {
            if (i == this.currentBrandRow.id) {
              this.isAllBrand.splice(idx, 1)
            }
          })
        }

        // goodsList({
        //   depart_id: this.id,
        //   brand_id: row.id,
        // }).then((res) => {
        //   this.goodsList = res.data
        //   this.goodsList.forEach((item) => {
        //     if (item.is_rel == 1) {
        //       this.$refs.goodsTable.toggleRowSelection(item, true)
        //     }
        //   })
        //   this.rTableLoding = false
        // })
      },
      handleTableHeadClick() {
        this.rTableLoding = true
        goodsListBMQX({
          depart_id: this.id,
          brand_id: '',
        }).then((res) => {
          this.goodsList = res.data
          this.rTableLoding = false
        })
      },
      // 商品全选狂
      handleGoodsAllSelect(val, c) {
        console.log('vv', val, c)
        this.true1 = false
        this.true2 = false
        if (this.goodsAllS) {
          this.currentBrandRow.is_rel = 1
          this.filterGoodsData.forEach((item) => {
            item.is_rel = 1
          })
          this.isAllBrand.push(this.currentBrandRow.id)
        } else {
          this.filterGoodsData.forEach((item) => {
            item.is_rel = 0
          })
          this.isAllBrand.forEach((i, idx) => {
            if (i == this.currentBrandRow.id) {
              this.isAllBrand.splice(idx, 1)
            }
          })
        }
      },
      getRowKey(row) {
        return row.id
      },
      asd(select) {
        console.log(select)
        // this.currentBrandRow.is_rel = 1
        // this.goodsSelectList = select
      },
      hanldeCheckAll(v, type) {
        console.log(v, type)
        if (type == 1 && v) {
          this.true2 = false
        } else if (type == 0 && v) {
          this.true1 = false
        }
        if (v) {
          this.classList.forEach((i) => {
            i.is_rel = type
          })
          this.brandList.forEach((i) => {
            i.is_rel = type
            if (i.children) {
              i.children.forEach((z) => {
                z.is_rel = type
                if (z.children) {
                  z.children.forEach((a) => {
                    a.is_rel = type
                  })
                }
              })
            }
          })
          this.goodsList.forEach((i) => {
            i.is_rel = type
          })
        }
      },

      handleChangeClassAll(val) {
        this.classAllCheckBox = val
        this.true1 = false
        this.true2 = false
        this.classList.forEach((i) => {
          i.is_rel = val ? 1 : 0
        })
      },
      handleChangeBrandAll(val) {
        this.brandAllCheckBox = val
        this.true1 = false
        this.true2 = false

        this.brandList.forEach((i) => {
          i.is_rel = val ? 1 : 0
          if (i.children) {
            i.children.forEach((z) => {
              z.is_rel = val ? 1 : 0
              if (z.children) {
                z.children.forEach((a) => {
                  a.is_rel = val ? 1 : 0
                })
              }
            })
          }
        })

        this.goodsList.forEach((i) => {
          i.is_rel = val ? 1 : 0
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
