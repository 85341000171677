var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "数据权限",
            visible: _vm.showDialog,
            width: "1000px",
            top: "5vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "orderTest-container",
              staticStyle: { "min-height": "500px" },
            },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v(
                    " 部门:" +
                      _vm._s((_vm.info && _vm.info.depart_name) || "") +
                      " "
                  ),
                ]),
              ]),
              _c(
                "el-radio-group",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  model: {
                    value: _vm.nav,
                    callback: function ($$v) {
                      _vm.nav = $$v
                    },
                    expression: "nav",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "1" } }, [
                    _vm._v("仓库权限"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "3" } }, [
                    _vm._v("商品权限"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "4" } }, [
                    _vm._v("客户权限"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "centent" },
                [
                  _vm.list.length > 0
                    ? _c("depot", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.nav == "1",
                            expression: "nav == '1'",
                          },
                        ],
                        ref: "depot",
                        attrs: { id: _vm.id, data: _vm.list },
                        on: {
                          "update:data": function ($event) {
                            _vm.list = $event
                          },
                          refresh: _vm.fetchData,
                          close: _vm.close,
                        },
                      })
                    : _vm._e(),
                  _vm.id != 0
                    ? _c(
                        "div",
                        [
                          _c("goods-permissions", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nav == "3",
                                expression: "nav == '3'",
                              },
                            ],
                            ref: "goodsPermissions",
                            attrs: { id: _vm.id },
                            on: { close: _vm.close },
                          }),
                          _c("client-permissions", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nav == "4",
                                expression: "nav == '4'",
                              },
                            ],
                            ref: "clientPermissions",
                            attrs: { id: _vm.id },
                            on: { close: _vm.close },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }