var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("el-alert", {
        attrs: {
          title: "默认为全部商品，勾选后，业务只可操作有权限的商品下单",
          type: "success",
          effect: "dark",
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.allLoading,
              expression: "allLoading",
            },
          ],
          staticStyle: { display: "flex", "justify-content": "space-around" },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "162px" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.classList,
                    border: "",
                    height: "500px",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: { align: "center", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-checkbox", {
                                attrs: { "true-label": 1, "false-label": 0 },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleClassSelect($event, row)
                                  },
                                },
                                model: {
                                  value: row.is_rel,
                                  callback: function ($$v) {
                                    _vm.$set(row, "is_rel", $$v)
                                  },
                                  expression: "row.is_rel",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.handleChangeClassAll },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "class_name",
                      label: "品类",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "280px" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.brandList,
                    border: "",
                    height: "500px",
                    "row-key": "id",
                    "default-expand-all": "",
                    indent: 35,
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                    "highlight-current-row": "",
                  },
                  on: { "current-change": _vm.handleCurrentChange },
                },
                [
                  _vm._v(" > "),
                  _c(
                    "el-table-column",
                    {
                      attrs: { type: "", align: "center", width: "50" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-checkbox", {
                                attrs: { "true-label": 1, "false-label": 0 },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleBrandSelect($event, row)
                                  },
                                },
                                model: {
                                  value: row.is_rel,
                                  callback: function ($$v) {
                                    _vm.$set(row, "is_rel", $$v)
                                  },
                                  expression: "row.is_rel",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c("el-checkbox", {
                            on: { change: _vm.handleChangeBrandAll },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    { attrs: { prop: "text", label: "品牌", align: "center" } },
                    [
                      _c("template", { slot: "header" }, [
                        _c("span", [_vm._v("品牌")]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "390px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.rTableLoding,
                      expression: "rTableLoding",
                    },
                  ],
                  ref: "goodsTable",
                  attrs: {
                    stripe: "",
                    data: _vm.filterGoodsData,
                    border: "",
                    height: "500px",
                    "row-key": "goods_id",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "label",
                              { staticStyle: { color: "red" } },
                              [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleGoodsAllSelect(
                                        $event,
                                        scope
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.goodsAllS,
                                    callback: function ($$v) {
                                      _vm.goodsAllS = $$v
                                    },
                                    expression: "goodsAllS",
                                  },
                                }),
                                _vm._v(" 全选 "),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": 1, "false-label": 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.handleGoodsSelect($event, row)
                                },
                              },
                              model: {
                                value: row.is_rel,
                                callback: function ($$v) {
                                  _vm.$set(row, "is_rel", $$v)
                                },
                                expression: "row.is_rel",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goods_name",
                      label: "商品名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specs",
                      label: "规格",
                      align: "center",
                      width: "110",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticStyle: { margin: "5px 18px" } },
        [
          _c("el-checkbox", {
            attrs: { label: "给予全部商品权限" },
            on: {
              change: function ($event) {
                return _vm.hanldeCheckAll($event, 1)
              },
            },
            model: {
              value: _vm.true1,
              callback: function ($$v) {
                _vm.true1 = $$v
              },
              expression: "true1",
            },
          }),
          _c("el-checkbox", {
            attrs: { label: "无商品权限" },
            on: {
              change: function ($event) {
                return _vm.hanldeCheckAll($event, 0)
              },
            },
            model: {
              value: _vm.true2,
              callback: function ($$v) {
                _vm.true2 = $$v
              },
              expression: "true2",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }